<template>
	<div class="messageWrap main">
		<h2 class="mb15" @click="open2">
			消息通知
			<span v-if="messageList.length != 0"
				>({{ messageList.length }}未读)</span
			>
		</h2>
		<div class="noData" v-if="messageList.length == 0">
			<img src="./assets/images/noData.png" alt="" />
			<p>暂无通知</p>
		</div>
		<div v-else class="messageBox">
			<ul>
				<li
					v-for="(item, index) in messageList"
					:key="index"
					@click="changeRead(item)"
				>
					<div class="messageTitle">
						<div class="titleLeft">
							<i
								class="mr8 grayDot"
								style="background: #fe4d67"
								v-if="true"
							></i>
							<h3>
								{{ item.title }}
							</h3>
						</div>
						<p>{{ item.make_time }}</p>
					</div>
					<div class="messageContent">
						<div class="contentBottom">
							<div>
								课程《深度学习应用开发-TensorFlow实践》的作业《第一周作业》批阅完成啦，点击查看>>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<p class="moreBtn" @click="getMore">
				<i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
			</p>
		</div>
	</div>
</template>

<script>
import { toMessageList, toMessageRead } from './assets/api';
export default {
	data() {
		return {
			page: 1,
			btnMessage: '加载更多',
			messageList: [],
		};
	},
	mounted() {
		this.getMessageList();
	},
	methods: {
		open2() {
			let _this = this;
			this.$notify({
				title: '作业批阅',
				message:
					'课程《深度学习应用开发-TensorFlow实践》的作业《第一周作业》批阅完成啦，点击查看>>',
				onClick() {
					_this.getMessageList(); //添加具体跳转的方法  调接口的时候具体在写
				},
			});
		},
		getMessageList() {
			const params = {
				page: this.page,
			};
			toMessageList(params).then((res) => {
				if (res.code == 0) {
					if (res.data.length > 0) {
						this.messageList = this.messageList.concat(res.data);
						console.log(this.messageList);
						this.$nextTick(() => {
							this.messageList.forEach((item, index) => {
								if (
									document.getElementsByClassName(
										'contentTop'
									)[index].offsetHeight >=
									document.getElementsByClassName(
										'contentTop'
									)[index].childNodes[0].offsetHeight
								) {
									this.$set(item, 'isShow', true);
								} else {
									this.$set(item, 'isShow', false);
								}
								this.$set(item, 'isHeight', false);
							});
						});
					} else {
						this.btnMessage = '暂无更多';
					}
				} else {
					this.page--;
				}
			});
		},
		getMore() {
			if (this.btnMessage == '加载更多') {
				this.page++;
				this.getMessageList();
			}
		},
		changeShow(item) {
			if (!item.isHeight) {
				this.changeRead(item);
			}
			item.isHeight = !item.isHeight;
		},
		changeRead(item) {
			const params = {
				notification_id: item.id,
			};
			toMessageRead(params).then((res) => {
				item.read = 1;
			});
		},
	},
};
</script>

<style scoped lang="scss">
.messageWrap {
	margin-top: 20px;
	background: #fff;
	border-radius: 8px;
	padding: 30px 0;
	box-sizing: border-box;
	& > h2 {
		padding-left: 24px;
		box-sizing: border-box;
	}
	.noData {
		margin-top: 115px;
		margin-bottom: 150px;
		display: flex;
		align-items: center;
		flex-direction: column;
		font-size: 16px;
		color: #999;
		img {
			width: 88px;
			height: 92px;
			margin-bottom: 25px;
		}
	}
	.messageBox {
		li {
			font-size: 14px;
			cursor: pointer;
			padding: 24px;
			padding-bottom: 0;
			box-sizing: border-box;
			&:hover {
				box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
				h3 {
					color: var(--default-color);
				}
			}
			.grayDot {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: #d5d5d5;
			}
			.messageTitle {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 10px;
				.titleLeft {
					display: flex;
					align-items: center;
					i {
						margin-right: 8px;
					}
					h3 {
						font-size: 16px;
					}
					.el-button.is-round {
						font-size: 12px;
						padding: 5px 7px;
						margin-left: 15px;
					}
					.colorBlue {
						color: #507fff;
					}
				}
				p {
					font-size: 14px;
					color: #999;
				}
			}
			.messageContent {
				box-sizing: border-box;
				line-height: 28px;
				padding-bottom: 24px;
				border-bottom: 1px dashed #e1e1e1;
				.contentTop {
					color: #666;
					max-height: 84px;
					overflow: hidden;
				}
				.heightAuto {
					max-height: 100%;
				}
				// .ellipsis3 {
				//   overflow: hidden;
				//   text-overflow: ellipsis;
				//   display: -webkit-box;
				//   -webkit-line-clamp: 3;
				//   -webkit-box-orient: vertical;
				// }
				.contentBottom {
					margin-top: 13px;
					color: #999;
					display: flex;
					align-items: center;
				}
			}
		}
		.moreBtn {
			margin: 0 auto;
			margin-top: 30px;
			width: 100px;
			height: 30px;
			font-size: 14px;
			border-radius: 18px;
			border: 1px solid #e1e1e1;
			text-align: center;
			line-height: 30px;
			cursor: pointer;
			color: #666;
			&:hover {
				background: var(--change-color);
				border: 1px solid var(--change-color);
				color: #fff;
			}
			i {
				font-size: 14px;
			}
		}
	}
}
</style>
